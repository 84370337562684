import { useEffect, useState } from 'react';
import { Box, CssBaseline } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles'
import Client from "./components/Client";
import NotFound from './components/NotFound'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import Axios from "axios";
import OverviewDialog from './components/OverviewDialog';
import { Amplify } from 'aws-amplify';
import { RedirectHandler, getCognitoConfig, getSession } from './auth/CognitoAuth';
import { WithAuthenticatorProps, useAuthenticator, withAuthenticator } from '@aws-amplify/ui-react';
import { signInWithRedirect } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';

const theme = createTheme({

  spacing: (factor: number) => `${1 * factor}rem`,
  palette: {
    primary: {
      light: '#2D69AF',
      main: '#002887',
      dark: '#001E55',
    },
    secondary: {
      light: '#C2185B',
      main: '#D2FF5A',
      dark: '#032b66',
      contrastText: '#344563',
    },
    text: {
      secondary: '#757575',
      disabled: '#9e9e9e',
      // hint: '#9e9e9e',
      // print: '#191919'
    },
    success: {
      main: '#D2FF5A'
    }
  },
})

// some comment to force deploymen

export const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [loggedInUser, setLoggedInUser] = useState({})
  useEffect(() => {
    getSession().then(session => {
      if (session) {
        console.log("Got session!")
        setLoggedInUser(session.userName)
        setIsLoggedIn(true)
      } else {
        console.log('No session, redirectLogin', session, window.location.href)
        signInWithRedirect({
          customState: window.location.href,
          provider: {
            custom: "haufead"
          }
        })
      }
    });
  }, [isLoggedIn])

  return (
    <ThemeProvider theme={theme} >
      <CssBaseline />
      <Box>

        <BrowserRouter>
          <Routes>
            <Route path="/clients" Component={Client} >

            </Route>
            {/* <Route path="/overview" Component={OverviewDialog}> */}
            {/* </Route> */}
            <Route path="/redirect" Component={RedirectHandler}>
            </Route>
            <Route path="/" Component={NotFound}>
            </Route>
            <Route Component={NotFound}>
            </Route>
          </Routes>
        </BrowserRouter>
      </Box>
    </ThemeProvider >

  )
};
