import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import history from './helper/history'
import { IntlProvider } from "react-intl";
import { z } from 'zod';
import { locale_de } from './translations/de';
import { locale_en } from './translations/en';
import { App } from './App';
import React from 'react';
import { getBackendConfig } from './helper/BackendInfrastructure';
import { Hub } from 'aws-amplify/utils';
import { Amplify } from 'aws-amplify';
import { getCognitoConfig } from './auth/CognitoAuth';

const data = {
    'de': locale_de,
    'en': locale_en
};

Hub.listen('auth', (event) => {
    if (event.payload.event === "customOAuthState" && window.location.pathname === "/redirect") {
        console.log("Received customOAuthState event, redirecting...")
        window.location.href = event.payload.data
    }
});
Amplify.configure(await getCognitoConfig());
const searchParams = new URLSearchParams(history.location.search)
const parsedLang = z.enum(['de', 'en']).safeParse(searchParams.get('lang'))
const lang = parsedLang.success ? parsedLang.data : 'de'
// ToDo: remove as and correct type
const translations = data[lang] as Record<string, string>
console.log("Initialize React");
const container = document.getElementById('root');
const rootNode = createRoot(container as Element);
rootNode.render(
    // <React.StrictMode>
    <IntlProvider locale={searchParams.get('lang') || 'de'} messages={translations}>
        <App />
    </IntlProvider>
    // </React.StrictMode>
);