import { z } from 'zod';

const backendConfigJsonSchema = z.object({
  AppBackendUrl: z.string(),
  CognitoClientId: z.string(),
  CognitoBaseURL: z.string(),
  CognitoUserPoolId: z.string()
}).strict();

export type BackendConfigJson = z.infer<typeof backendConfigJsonSchema>;

export type GetBackendConfigResult = {
  success: true,
  config: BackendConfigJson,
} | {
  success: false,
  errorCode: 'FETCH_FAILED' | 'PARSE_FAILED',
};

let backendConfig: BackendConfigJson | undefined;

export const getBackendConfig = async (): Promise<GetBackendConfigResult> => {
  if (backendConfig) {
    return {
      success: true,
      config: backendConfig,
    } as const;
  }

  const envAppBackendUrl = import.meta.env.VITE_APP_BACKEND_URL;
  const envCognitoClientId = import.meta.env.VITE_APP_COGNITO_CLIENTID;
  const envCognitoBaseURL = import.meta.env.VITE_APP_COGNITO_BASEURL;
  const envCognitoUserPoolId = import.meta.env.VITE_APP_COGNITO_USER_POOL_ID;

  if (envAppBackendUrl && window.location.hostname === 'localhost') {
    backendConfig = {
      AppBackendUrl: envAppBackendUrl,
      CognitoClientId: envCognitoClientId,
      CognitoBaseURL: envCognitoBaseURL,
      CognitoUserPoolId: envCognitoUserPoolId,
    };

    return {
      success: true,
      config: backendConfig,
    } as const;
  }
  const result = await fetch('/backend-config.json');
  if (result.status !== 200) {
    return {
      success: false,
      errorCode: 'FETCH_FAILED',
    } as const;
  }

  const config = await result.json();
  const parsedConfig = backendConfigJsonSchema.safeParse(config);
  if (!parsedConfig.success) {
    return {
      success: false,
      errorCode: 'PARSE_FAILED',
    } as const;
  }

  // eslint-disable-next-line require-atomic-updates
  backendConfig = parsedConfig.data;
  return {
    success: true,
    config: parsedConfig.data,
  } as const;
};

const fetchWithTimeout = async (resource: string, timeout: number) => {

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    signal: controller.signal,
    mode: 'no-cors',
  });
  clearTimeout(id);

  return response;
};

export const checkIfBackendIsReachable = async (): Promise<boolean> => {
  const backCfg = await getBackendConfig();
  if (!backCfg.success) {
    return false;
  }

  try {
    await fetchWithTimeout(backCfg?.config?.AppBackendUrl, 4_000);
    return true;
  } catch {
    return false;
  }
};
