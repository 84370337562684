import { ResourcesConfig } from "aws-amplify";
import { getBackendConfig } from "../helper/BackendInfrastructure";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import { useEffect } from "react";
import { Hub } from "aws-amplify/utils";

const BASE_URL = `${window.location.protocol}//${window.location.host}`

export const RedirectHandler = () => {
    useEffect(() => {
        // const state = new URLSearchParams(window.location.search).get('state');
        // if (!state) {
        //     console.error('No state found in URL');
        //     return;
        // } else {
        //     // Redirect to the state
        //     const decoded = btoa(state);
        //     console.log('Redirecting to:', decoded)
        //     window.location.href = decoded;
        // }

        const deListen = Hub.listen('auth', (data) => {
            console.log("Auth Event", data);
        });

        return () => {
            deListen();
        }
    }, []);
    return <div>Redirecting...</div>
}

export async function getCognitoConfig() {
    const backendConfig = await getBackendConfig();
    if (!backendConfig.success) {
        console.error('Failed to get backend config')
        throw new Error('Failed to get backend config')
    }
    const config = backendConfig.config;
    return {
        Auth: {
            Cognito: {
                userPoolClientId: config.CognitoClientId,
                userPoolId: config.CognitoUserPoolId,
                loginWith: {
                    oauth: {
                        domain: config.CognitoBaseURL,
                        scopes: [
                            "email",
                            "profile",
                            "openid",
                        ],
                        redirectSignIn: [location.protocol + '//' + location.host + "/redirect"],
                        redirectSignOut: [`${BASE_URL}/auth/logout`],
                        responseType: "code",
                    },
                }
            }
        },
    } satisfies ResourcesConfig;
}

export const getSession = async () => {
    try {
        const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
        if (!accessToken || !idToken) return undefined;

        const userData = await getCurrentUser();
        const userName = userData.username;
        return { accessToken, idToken, userName };
    } catch (err) {
        console.log(err);
    }
}