import { AppBar, Stack, Toolbar, Typography } from "@mui/material"

export const Footer = () => {
    return (
        <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
            <Toolbar>
                <Stack direction="row" spacing={2} justifyContent="center">
                    <Typography variant="body1" color="inherit">
                        Made by Business Enablement & Innovation
                    </Typography>
                </Stack>
            </Toolbar>
        </AppBar>
    )
}