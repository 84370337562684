import React, { useState, useEffect, useCallback } from "react";
import { Grid, MenuItem, FormControl, InputLabel, Select, FormControlLabel, Switch, Typography, Stack, Box, Card, CardContent, Button } from '@mui/material';
import { FormattedMessage } from "react-intl";
import translateBrand from "../translations/translateBrand"
import { locale_de } from "../translations/de";


const formControlSX = {
    minWidth: 290,
    minHeight: 55,
}

const switchSX = {
    marginLeft: "0px !important"
}


const Channels = (props) => {
    const brandList = Object.values(locale_de.brandOptions).map(value => value)
    const durationList = Object.values(locale_de.durationOptions).map(value => value)


    const [brand, setBrand] = useState('');
    const [duration, setDuration] = useState(locale_de.durationOptions.option5);
    const [email, setEmail] = useState(false)
    const [allPrint, setAllPrint] = useState(false)
    const [bigCatalog, setBigCatalog] = useState(false)
    const [telephone, setTelephone] = useState(false)
    const [print, setPrint] = useState(false)
    const [marketResearch, setMarketResearch] = useState(false)
    const [allChannels, setAllChannels] = useState(false)
    const [client, setClient] = useState(props.client)
    const [debi, setDebi] = useState(props.debi)
    let selectedBrand = translateBrand(brand)
    const getSelectedClient = useCallback(() => client.selected === true ? client : debi, [client, debi])

    useEffect(() => {
        setClient(props.client)
        setDebi(props.debi)
    }, [props.client, props.debi])

    useEffect(() => {
        if (email === true && allPrint === true && bigCatalog === true && telephone === true && print === true && marketResearch === true)
            setAllChannels(true)
    }, [email, allPrint, bigCatalog, telephone, print, marketResearch])

    useEffect(() => {
        props.updateBrand(brand)
    }, [brand])

    useEffect(() => {
        setDefault()
    }, [client.selected])

    useEffect(() => {
        const hard_escalation = debi.hard_escalation

        if (hard_escalation === true) {
            setHardEscalation()
        } else {
            setDefault()
        }
    }, [debi.hard_escalation])

    const setHardEscalation = () => {
        setAllChannels(true)
        setBrand(locale_de.brandOptions.option1)
        setDuration(locale_de.durationOptions.option5)
        setToggles(true)
    }

    const setDefault = () => {
        setBrand('')
        setDuration(locale_de.durationOptions.option5)
        setAllChannels(false)
        setToggles(false)
    }

    const setToggles = (value) => {
        setEmail(value)
        setAllPrint(value)
        setBigCatalog(value)
        setTelephone(value)
        setPrint(value)
        setMarketResearch(value)
    }

    const getUpdates = () => getSelectedClient().updates ? getSelectedClient().updates[brand] : {}


    const handleBrand = event => {
        const value = event.target.value
        let brand = translateBrand(value)
        const selectedClient = getSelectedClient();
        console.log("SelectedClient", selectedClient)
        const brandInClient = !!selectedClient && !!selectedClient[brand]
        const brandResolved = brandInClient ? getSelectedClient()[brand] : {}
        let checkboxes = Object.entries(brandResolved)
            .map(([key, value]) => ({ key, value }))
        const duration = brandResolved.duration ? brandResolved.duration : 'Für immer'
        setBrand(value)
        setDuration(duration)
        setAllChannels(false)
        setEmail(toggleFilterByLabel(checkboxes, 'email'))
        setAllPrint(toggleFilterByLabel(checkboxes, 'allPrint'))
        setBigCatalog(toggleFilterByLabel(checkboxes, 'bigCatalog'))
        setTelephone(toggleFilterByLabel(checkboxes, 'telephone'))
        setPrint(toggleFilterByLabel(checkboxes, 'print<50pages'))
        setMarketResearch(toggleFilterByLabel(checkboxes, 'marketResearch'))

    }

    const toggleFilterByLabel = (checkboxes, label) => {
        let toggle = checkboxes.filter(item => item.key === label)

        if (toggle[0] !== undefined)
            return toggle[0].value
        return false
    }

    const handleDuration = event => {
        const value = event.target.value
        let duration = value
        let update = getUpdates()

        setDuration(value)
        if (client.selected === true)
            props.updateClient({
                ...client,
                [selectedBrand]: { ...client[selectedBrand], duration },
                updates: { ...client.updates, [brand]: { ...update, [locale_de.duration]: duration } }
            })
        else if (debi.selected === true)
            props.updateDebi({
                ...debi,
                [selectedBrand]: { ...debi[selectedBrand], duration },
                updates: { ...debi.updates, [brand]: { ...update, [locale_de.duration]: duration } }
            })
    }

    const selectAllChannels = (item) => {
        return {
            'duration': duration,
            'email': item,
            "allPrint": item,
            "telephone": item,
            "bigCatalog": item,
            "marketResearch": item,
            "print<50pages": item,
        }
    }

    const selectAllChannelsUpdates = (item) => {
        return {
            [locale_de.duration]: duration,
            [locale_de.email]: locale_de[item],
            [locale_de.allPrint]: locale_de[item],
            [locale_de.telephone]: locale_de[item],
            [locale_de.bigCatalog]: locale_de[item],
            [locale_de.marketResearch]: locale_de[item],
            [locale_de['print<50pages']]: locale_de[item],
        }
    }

    const updateClientToggle = (label, checked) => {
        let update = getUpdates()
        if (client.selected === true) {
            props.updateClient({
                ...client,
                [selectedBrand]: {
                    ...client[selectedBrand],
                    duration: duration,
                    [label]: checked
                },
                updates: {
                    ...client.updates,
                    [brand]: {
                        ...update,
                        [locale_de.duration]: duration,
                        [locale_de[label]]: locale_de[checked]
                    }
                }
            })
        }
        else if (debi.selected === true)
            props.updateDebi({
                ...debi,
                [selectedBrand]: {
                    ...debi[selectedBrand],
                    duration: duration,
                    [label]: checked
                },
                updates: {
                    ...debi.updates,
                    [brand]: {
                        ...update,
                        [locale_de.duration]: duration,
                        [locale_de[label]]: locale_de[checked]
                    }
                }
            })
    }

    const handleSelectAllChannels = () => {

        setAllChannels(!allChannels)
        setToggles(!allChannels)
        setDuration(!allChannels ? duration : locale_de.durationOptions.option5)
        if (client.selected === true)
            props.updateClient({
                ...client,
                [selectedBrand]: {
                    ...client[selectedBrand],
                    ...selectAllChannels(!allChannels)
                },
                updates: {
                    ...client.updates,
                    [brand]: {
                        ...(client.updates && client.updates[brand]),
                        ...selectAllChannelsUpdates(!allChannels)
                    }
                }
            })
        else if (debi.selected === true)
            props.updateDebi({
                ...debi,
                [selectedBrand]: {
                    ...debi[selectedBrand],
                    ...selectAllChannels(!allChannels)
                },
                updates: {
                    ...debi.updates,
                    [brand]: {
                        ...(debi.updates && debi.updates[brand]),
                        ...selectAllChannelsUpdates(!allChannels)
                    }
                }
            })
    }

    const onChangeToggleEmail = (event) => {
        let checked = event.target.checked

        if (allChannels === true)
            setAllChannels(false)
        if (getSelectedClient().selected) {
            setEmail(checked)
            updateClientToggle('email', checked)
        }
    }

    const onChangeToggleAllPrint = (event) => {
        let checked = event.target.checked

        if (allChannels === true)
            setAllChannels(false)
        setAllPrint(checked)
        updateClientToggle('allPrint', checked)
    }

    const onChangeToggleBigCatalog = (event) => {
        let checked = event.target.checked

        if (allChannels === true)
            setAllChannels(false)
        setBigCatalog(checked)
        updateClientToggle('bigCatalog', checked)
    }

    const onChangeToggleTelephone = (event) => {
        let checked = event.target.checked

        if (allChannels === true)
            setAllChannels(false)
        if (getSelectedClient().selected) {
            setTelephone(checked)
            updateClientToggle('telephone', checked)
        }
    }

    const onChangeTogglePrint = (event) => {
        let checked = event.target.checked

        if (allChannels === true)
            setAllChannels(false)
        setPrint(checked)
        updateClientToggle('print<50pages', checked)
    }

    const onChangeToggleMarketResearch = (event) => {
        let checked = event.target.checked

        if (allChannels === true)
            setAllChannels(false)
        setMarketResearch(checked)
        updateClientToggle('marketResearch', checked)
    }
    const isSaveDisabled = props.enableSave ? props.enableSave() : false
    return (
        <Card sx={{ marginTop: 2, borderRadius: "5px 5px 0px 0px" }}>
            <CardContent>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Grid container spacing={2}>
                        <Grid item >
                            <FormControl variant="outlined" sx={formControlSX}>
                                <InputLabel><FormattedMessage id="brand" /></InputLabel>
                                <Select
                                    disabled={debi.hard_escalation}
                                    value={brand || ''}
                                    onChange={handleBrand}
                                    label={<FormattedMessage id="brand" />}
                                >
                                    {brandList.map((name, index) =>
                                        <MenuItem value={name} key={index.toString()}>{name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl variant="outlined" sx={formControlSX}>
                                <InputLabel><FormattedMessage id="duration" /> (Optional)</InputLabel>
                                <Select

                                    disabled={debi.hard_escalation || brand === ''}
                                    // className={classes.selectEmpty}
                                    value={duration || locale_de.durationOptions.option5}
                                    onChange={handleDuration}
                                    label={<><FormattedMessage id="duration" /> (Optional)</>}
                                >
                                    {durationList.map((name, index) =>
                                        <MenuItem value={name} key={index.toString()}>{name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Button
                        disabled={isSaveDisabled}
                        onClick={() => props.submit && props.submit()}
                        color="success"
                        variant="contained"
                        sx={{ maxHeight: 40, width: 200 }}
                    >
                        {isSaveDisabled === true ? <FormattedMessage id="noChangedData" /> : <FormattedMessage id="buttonSaveText" />}
                    </Button>
                </Stack>

                <Typography variant="h6" sx={{ marginTop: 2 }}><FormattedMessage id="channels" /></Typography>
                <Grid container>
                    <Grid item xs={3}>
                        <FormControlLabel
                            // className={classes.checkbox}
                            sx={switchSX}
                            value="top"
                            control={
                                <Switch
                                    disabled={debi.hard_escalation || brand.length === 0}
                                    checked={email}
                                    onChange={onChangeToggleEmail}
                                    color="primary"
                                />
                            }
                            label='E-Mail'
                            labelPlacement="start"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            // className={classes.checkbox}
                            sx={switchSX}
                            value="top"
                            control={
                                <Switch
                                    disabled={debi.hard_escalation || brand.length === 0}
                                    checked={allPrint}
                                    onChange={onChangeToggleAllPrint}
                                    color="primary"
                                />
                            }
                            label={<FormattedMessage id="allPrint" />}
                            labelPlacement="start"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            // className={classes.checkbox}
                            sx={switchSX}
                            value="top"
                            control={
                                <Switch
                                    disabled={debi.hard_escalation || brand.length === 0}
                                    checked={bigCatalog}
                                    onChange={onChangeToggleBigCatalog}
                                    color="primary"
                                />
                            }
                            label={<FormattedMessage id="bigCatalog" />}
                            labelPlacement="start"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            sx={switchSX}
                            value="top"
                            control={<Switch
                                disabled={debi.hard_escalation || brand.length === 0}
                                checked={allChannels}
                                name="allChannels"
                                color="primary"
                                onChange={handleSelectAllChannels}
                            />}
                            label={<FormattedMessage id="allChannels" />}
                            labelPlacement="start"
                        />
                    </Grid>
                    {/* ------ */}
                    <Grid item xs={3}>
                        <FormControlLabel
                            sx={switchSX}
                            value="top"
                            control={
                                <Switch
                                    disabled={debi.hard_escalation || brand.length === 0}
                                    checked={telephone}
                                    onChange={onChangeToggleTelephone}
                                    color="primary"
                                />
                            }
                            label={<FormattedMessage id="telephone" />}
                            labelPlacement="start"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            sx={switchSX}
                            value="top"
                            control={
                                <Switch
                                    disabled={debi.hard_escalation || brand.length === 0}
                                    checked={print}
                                    onChange={onChangeTogglePrint}
                                    color="primary"
                                />
                            }
                            label={<FormattedMessage id="print<50pages" />}
                            labelPlacement="start"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            sx={switchSX}
                            value="top"
                            control={
                                <Switch
                                    disabled={debi.hard_escalation || brand.length === 0}
                                    checked={marketResearch}
                                    onChange={onChangeToggleMarketResearch}
                                    color="primary"
                                />
                            }
                            label={<FormattedMessage id="marketResearch" />}
                            labelPlacement="start"
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card >
    )

}

export default Channels