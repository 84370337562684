import { Typography, Button, Box, AppBar, Toolbar, } from '@mui/material';
import { FormattedMessage } from "react-intl";

const Header = (props: { enableSave?: () => boolean, submit?: () => void }) => {

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="fixed">
                    <Toolbar>
                        <img src="/HG_Logo_Standard_quer_einzeilig_neg_RGB.png" alt="Logo" style={{ height: 35 }} />
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1, marginLeft: 1 }}>
                            Werbesperre Service
                        </Typography>

                    </Toolbar>
                </AppBar>
            </Box>
            {/* <Grid container >
                <Grid item container
                    sx={{
                        marginTop: 2,
                        marginLeft: 3,
                        marginRight: 3,
                        marginBottom: 1
                    }}
                >
                    <Grid item container xs={12}>
                        <Grid item container xs={6} >
                            <Grid item xs={5}>
                                <Typography variant="h5" >

                                </Typography>
                            </Grid>
                            <Grid item xs={3} container alignItems="center">
                                <Grid item >
                                    <Typography variant="h6" >
                                        {selectedClient.company_wsp === true ? <FormattedMessage id="companyWSPTitle" /> : <FormattedMessage id="personalWSPTitle" />}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container xs={6} justifyContent="flex-end">
                            <Grid item>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1">
                            Agent: {userName}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid> */}
            {/* <Divider
                sx={{
                    marginLeft: 3,
                    marginRight: 3,
                }}
            /> */}
        </>
    )
}

export default Header