import React from 'react'
import { Collapse, IconButton } from '@mui/material';
import Alert from '@mui/material/Alert'
import CloseIcon from '@mui/icons-material/Close';
import {  FormattedMessage } from "react-intl";

const Error = (props) => {
    return (
        <Collapse in={props.alert}>
            <Alert
                severity="error"
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={props.closeAlert}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
            >
               <FormattedMessage id="errorWarningOnSave" />
            </Alert>
        </Collapse>
    )
}

export default Error