import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert'
import { Collapse, IconButton } from '@mui/material';


const Warning = (props) => {
    // const classes = useStyles();

    return (
        <Collapse sx={{
            marginLeft: 3,
            marginRight: 3
        }} in={props.warning}>
            <Alert
                severity="warning"
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={props.closeWarning}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
            >
                {props.text}
            </Alert>
        </Collapse>
    )
}

export default Warning