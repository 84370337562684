import React, { useState, useEffect } from "react";
import { Grid, Typography, MenuItem, FormControl, InputLabel, Select, Dialog, DialogTitle, Button, DialogContent, DialogContentText, DialogActions, CardContent, Card, Alert } from '@mui/material';
import { FormattedMessage } from "react-intl";
import translateBrand from "../translations/translateBrand"
import { locale_de } from "../translations/de";


const formSx = {
    minWidth: 290,
    minHeight: 55,
    color: 'white',
    fontWeight: 'bold'
}


const ContactPerson = (props) => {
    const levelList = Object.values(locale_de.levelOptions).map(value => value)
    // const groupList = Object.values(locale_de.groupOptions).map(value => value)
    const departmentList = Object.values(locale_de.departmentOptions).map(value => value)
    let selectedBrand = translateBrand(props.brand)
    // const classes = useStyles();
    const [client, setClient] = useState(props.client)
    const [debi, setDebi] = useState(props.debi)
    const getSelectedClient = () => client.selected === true ? client : debi
    const [level, setLevel] = useState(getSelectedClient() === debi ? (debi[selectedBrand] && debi[selectedBrand].level) : '');
    // const [groups, setGroups] = useState(getSelectedClient().groups);
    const [department, setDepartment] = useState(getSelectedClient() === debi ? (debi[selectedBrand] && debi[selectedBrand].department) : []);
    const [open, setOpen] = useState(false)
    const [brand, setBrand] = useState(props.brand)
    const [oldLevel, setOldLevel] = useState(null)

    useEffect(() => {
        setClient(props.client)
        setDebi(props.debi)
        setBrand(props.brand)
    }, [props.client, props.debi, props.brand])

    useEffect(() => {
        setLevel('')
        setDepartment([])
    }, [client.selected, debi.hard_escalation])


    useEffect(() => {
        const selectedClient = getSelectedClient();
        const brandInClient = !!selectedClient && !!selectedClient[selectedBrand]
        const brandResolved = brandInClient ? getSelectedClient()[selectedBrand] : {}
        setLevel(brandResolved !== null ? brandResolved.level : '')
        setDepartment(brandResolved !== null ? brandResolved.department : [])
    }, [selectedBrand])


    const handleLevel = (event) => {
        let newLevel = event.target.value
        setOldLevel(level)
        setLevel(newLevel)
        if (level === 'Account und alle Kontakte gesperrt' && newLevel !== 'Account und alle Kontakte gesperrt') {
            setOpen(true)
        } else {
            props.updateDebi({
                ...debi,
                [selectedBrand]: {
                    ...debi[selectedBrand],
                    level: newLevel,
                    // department: newLevel !== department ? [] : department,
                    ...(newLevel !== department) && { department: department }
                },
                updates: {
                    ...debi.updates,
                    [brand]: {
                        ...(debi.updates && debi.updates[brand]),
                        [locale_de.level]: newLevel,
                        // [locale_de.department]: newLevel !== department ? [] : department,
                        ...(newLevel !== department) && { [locale_de.department]: department }
                    },
                }
            })
        }
        newLevel !== locale_de.department && setDepartment([])
    }

    const handleCloseAndChange = () => {
        setOpen(false);
        props.updateDebi({
            ...debi,
            [selectedBrand]: {
                ...debi[selectedBrand],
                level: level
            },
            updates: {
                ...debi.updates,
                [brand]: {
                    ...(debi.updates && debi.updates[brand]),
                    [locale_de.level]: level
                }
            }
        })
    };

    const handleClose = () => {
        setOpen(false);
        setLevel(oldLevel)
        props.updateDebi({
            ...debi,
            [selectedBrand]: {
                ...debi[selectedBrand],
                level: oldLevel
            },
            updates: {
                ...debi.updates
            }
        })
    }



    const handleDepartment = event => {
        let department = event.target.value
        setDepartment(department);
        props.updateDebi({
            ...debi,
            [selectedBrand]: {
                ...debi[selectedBrand],
                department
            },
            updates: {
                ...debi.updates,
                [brand]: {
                    ...(debi.updates && debi.updates[brand]),
                    [locale_de.department]: department
                }
            }
        })
    }

    let enableGroup = true
    if (debi.hard_escalation)
        enableGroup = true
    else if (debi.selected)
        if (debi.updates !== undefined || JSON.stringify(debi).includes("duration"))
            enableGroup = false
        else enableGroup = true
    else if (client.selected)
        if (client.updates !== undefined || JSON.stringify(client).includes("duration"))
            enableGroup = false
        else enableGroup = true
    else enableGroup = false

    const levelSelectDisabled = client.selected || debi.hard_escalation || ((level === undefined || level === '') && debi.updates === undefined)
    const departmentSelectDisabled = ((level === locale_de.levelOptions.option1) && (debi.selected) ? false : true) || debi.hard_escalation

    return (
        <Card sx={{ borderRadius: "0px 0px 5px 5px", borderTop: "1px solid #dddddd" }}>
            <CardContent>
                <Grid container>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                    >
                        <DialogTitle >
                            <Typography color='error'>
                                Warnung !!!
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText component={'span'}>
                                <Typography color='error'>
                                    Wenn Sie die Ebene wechseln, dann geht die aktuelle Werbesperre für die ganze 'Account und alle Kontakte gesperrt' verloren und
                                    die Werbesperre gilt nur noch für einen Teil der Firma.Sind Sie sich sicher?
                                </Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} variant="contained">
                                Nein, abbrechen
                            </Button>
                            <Button onClick={handleCloseAndChange} color="primary" variant="contained" autoFocus>
                                Ja, ändern
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Typography variant="h6" gutterBottom>
                        <FormattedMessage id="appliesTo" />
                    </Typography>
                    <Grid item container spacing={2}>
                        {!levelSelectDisabled && <Grid item >
                            <FormControl variant="outlined" sx={formSx}>
                                <InputLabel><FormattedMessage id="level" /></InputLabel>
                                <Select
                                    disabled={levelSelectDisabled}
                                    value={level || ''}
                                    onChange={handleLevel}
                                    label={<FormattedMessage id="level" />}
                                >
                                    {levelList.map((name, index) =>
                                        <MenuItem value={name} key={index.toString()}>{name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>}
                        {!departmentSelectDisabled && <Grid item >
                            <FormControl variant="outlined" sx={formSx}>
                                <InputLabel><FormattedMessage id="department" /></InputLabel>
                                <Select
                                    disabled={departmentSelectDisabled}
                                    value={department || []}
                                    onChange={handleDepartment}
                                    label={<FormattedMessage id="department" />}
                                    multiple
                                    renderValue={(selected) => selected.join(', ')}
                                >
                                    {departmentList.map((name, index) =>
                                        <MenuItem value={name} key={index.toString()}>{name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>}
                    </Grid>
                </Grid>
                {levelSelectDisabled && departmentSelectDisabled && <Alert sx={{ backgroundColor: "#002887", color: "white" }} severity="info">
                    <FormattedMessage id={client.selected ? "levelDepartmentDisabledAsp" : "levelDepartmentDisabled"} />
                </Alert>}
            </CardContent>
        </Card>
    )
}

export default ContactPerson