import React, { useState, useEffect, useCallback } from "react";
import { Grid, Typography, Card, CardContent, Button, Stack, Box, Tabs, Tab, CardActions, Tooltip, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import isEmptyObj from '../helper/isEmptyObj'
import HardEscalationDialog from './HardEscalationDialog'
import Warning from "./Warning";
import SaveChangesOverviewDialog from './SaveChangesOverviewDialog'
import SwitchUserDialog from './SwitchUserDialog'
import { FormattedMessage } from "react-intl";
import { locale_de } from "../translations/de";
import { ExpandMore } from "@mui/icons-material";
import OverviewDialog from "./OverviewDialog";

const styles = {
    container: {
        marginTop: 3,
        marginLeft: 3,
        marginRight: 3
    },
    alert: {
        marginLeft: 3,
        marginRight: 3
    },
    card: {
        border: "1px solid #09a1e2",
        minHeight: 193,
        minWidth: 400,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#eff8fa",
            color: "#09a1e2",
        }
    },
    cardContent: {
        paddingLeft: 2
    },
    formControl: {
        // minWidth: 290,
        // minHeight: 55,
        // color: 'white',
        // fontWeight: 'bold',
    },
    assistiveText: {
        width: 290,
        minWidth: 290,
        minHeight: 55,
        fontWeight: 'bold',
        marginTop: 10
    }
};

const ClientInfo = (props: any) => {

    const caseIdShort = props.caseIdShort
    const [client, setClient] = useState(props.client)
    const [debi, setDebi] = useState(props.debi)
    const getSelectedClient = () => client.selected === true ? client : debi
    const [backgroundColorUser, setBackgroundColorUser] = useState(client.selected === true ? '#d8eef3' : '')
    const [backgroundColorCompany, setBackgroundColorCompany] = useState(debi.selected === true ? '#d8eef3' : '')
    const [open, setOpen] = useState(false)
    const [openSaveChanges, setOpenSaveChanges] = useState(false)
    const [warningHardEscalation, setWarningHardEscalation] = useState(false)
    const [warningNoUser, setWarningNoUser] = useState(false)
    const [dialogSwitchUser, setDialogSwitchUser] = useState(false)
    const selectedClient = client.selected ? client : debi
    const [tabState, setTabState] = useState(0)
    const [groups, setGroups] = useState(getSelectedClient().groups);

    const groupList = Object.values(locale_de.groupOptions).map(value => value)

    const allChecked = {
        'allPrint': true,
        'bigCatalog': true,
        'email': true,
        'marketResearch': true,
        'print<50pages': true,
        'telephone': true
    }

    const allCheckedUpdates = {
        [locale_de.email]: locale_de["true"],
        [locale_de.allPrint]: locale_de["true"],
        [locale_de.telephone]: locale_de["true"],
        [locale_de.bigCatalog]: locale_de["true"],
        [locale_de.marketResearch]: locale_de["true"],
        [locale_de['print<50pages']]: locale_de["true"],
    }

    useEffect(() => {
        setClient(props.client)
        setDebi(props.debi)
        setGroups(props.client.selected === true && props.debi.hard_escalation !== true ? props.client.groups : props.debi.groups)
    }, [props.client, props.debi])

    const selectPersonal = () => {
        setBackgroundColorUser('#d8eef3')
        setBackgroundColorCompany('')
        props.setClientSelection(false)
    }

    const selectCompany = () => {
        setBackgroundColorUser('')
        setBackgroundColorCompany('#d8eef3')
        props.setClientSelection(true)
    }

    const handleChangePersonal = () => {
        if (debi.hard_escalation !== true) {
            if (isEmptyObj(client))
                setWarningNoUser(true)
            else if (debi.updates !== undefined) {
                setDialogSwitchUser(true)
            }
            else selectPersonal()
        }
        else setWarningHardEscalation(true)
    };

    const handleChangeCompany = () => {
        if (debi.hard_escalation !== true) {
            if (!isEmptyObj(client)) {
                if (client.updates !== undefined) {
                    setDialogSwitchUser(true)
                }
                else selectCompany()
            }
        }
    }

    const handleAcceptHardEscalation = () => {
        setOpen(false)
        setWarningHardEscalation(false)
        props.updateDebi(
            {
                ...debi,
                hard_escalation: true,
                selected: true,
                groups: locale_de.groupOptions.option1,
                all_brands: {
                    level: locale_de.levelOptions.option4,
                    duration: locale_de.durationOptions.option5,
                    ...allChecked
                },
                haufe_brand: {},
                akademie_brand: {},
                sp_brand: {},
                lexware_brand: {},
                updates:
                {
                    [locale_de.all]: {
                        [locale_de.duration]: locale_de.durationOptions.option5,
                        [locale_de.level]: locale_de.levelOptions.option4,
                        ...allCheckedUpdates
                    },
                    [locale_de.groups]: locale_de.groupOptions.option1,
                }
            }
        )
        if (!isEmptyObj(client)) {
            setBackgroundColorUser('')
            setBackgroundColorCompany('#d8eef3')
            props.updateClient({
                ...client,
                selected: false
            })
        }
    };

    const handleRemoveHardEscalation = () => {

        const storedCompany = JSON.parse(localStorage.getItem('storedCompany')!)
        setOpen(false)
        setWarningHardEscalation(false)

        if (JSON.stringify(storedCompany) !== JSON.stringify(debi)) {

            props.updateDebi({
                ...storedCompany,
                selected: true,
            })
        }
        else {
            props.updateDebi({
                ...debi,
                hard_escalation: false,
                groups: null,
                selected: true,
                all_brands: {},
                haufe_brand: {},
                akademie_brand: {},
                sp_brand: {},
                lexware_brand: {},
                updates:
                {
                    [locale_de.all]: {},
                    [locale_de.groups]: null,
                }
            })
        }
        props.updateClient({
            ...client,
            selected: false
        })
    }

    const closeHardEscalationDialog = () => {
        setOpen(false)
    }

    const handleHardEscalationButton = () => {
        setOpen(true)
    }

    const closeWarningHardEscalation = () => {
        setWarningHardEscalation(false)
    }

    const closeWarningNoUser = () => {
        setWarningNoUser(false)
    }

    const closeSaveChangesDialog = () => {
        setOpenSaveChanges(false)
    }

    const openSaveChangesDialog = () => {
        setOpenSaveChanges(true)
    }

    const acceptSaveChangesDialog = () => {
        closeSaveChangesDialog()
        props.enableOverviewClient()
        props.submit()
    }

    const closeDialogSwitchUser = () => {
        setDialogSwitchUser(false)
    }

    const switchUser = () => {
        closeDialogSwitchUser()
        if (client.selected) selectCompany()
        else selectPersonal()
    }

    const enableButton = () => {
        if (selectedClient.updates === undefined)
            return false
        else return props.enableSave()

    }

    const handleGroups = (event: any) => {
        let groups = event.target.value
        if (groups === '')
            groups = null
        setGroups(groups);
        if (debi.selected === true)
            props.updateDebi({
                ...debi,
                groups,
                updates: {
                    ...debi.updates,
                    [locale_de.groups]: groups
                }
            })
        else if (client.selected === true)
            props.updateClient({
                ...client,
                groups,
                updates: {
                    ...client.updates,
                    [locale_de.groups]: groups
                }
            })
    }

    const hasAccountId = useCallback(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const accountId = urlParams.get('accountId')
        return accountId !== null
    }, []);

    useEffect(() => {
        if (tabState === 0) handleChangePersonal()
        else handleChangeCompany()
    }, [tabState])

    let enableGroup = true
    if (debi.hard_escalation)
        enableGroup = true
    else if (debi.selected)
        if (debi.updates !== undefined || JSON.stringify(debi).includes("duration"))
            enableGroup = false
        else enableGroup = true
    else if (client.selected)
        if (client.updates !== undefined || JSON.stringify(client).includes("duration"))
            enableGroup = false
        else enableGroup = true
    else enableGroup = false

    return (
        <Box>
            <Warning
                text={<FormattedMessage id="warningHardEscalationOn" />}
                warning={warningHardEscalation}
                closeWarning={closeWarningHardEscalation}
            />
            <Warning
                text={<FormattedMessage id="warningNoContactId" />}
                warning={warningNoUser}
                closeWarning={closeWarningNoUser}
            />
            <Box sx={{ borderBottom: 2, borderColor: 'divider', marginTop: 1 }}>
                <Tabs value={tabState} onChange={(_e, val) => setTabState(val)} aria-label="basic tabs example">
                    <Tab label={<FormattedMessage id="personalWSPTitle" />} />
                    {hasAccountId() && <Tab label={<FormattedMessage id="companyWSPTitle" />} />}
                </Tabs>
            </Box>
            <Card sx={{ marginTop: 2, paddingBottom: 0.5, paddingLeft: 0.5, borderRadius: "5px 5px 0px 0px" }}>
                <CardContent>
                    {client.selected && <Box>
                        <Typography variant="h6">
                            Name: {client.name !== undefined ? client.name : <FormattedMessage id="unknown" />}
                        </Typography>
                        <br />
                        <Typography>
                            <FormattedMessage id="personalWSPTitle" />-ID:  {client.sap_ansp_id || <FormattedMessage id="unknown" />}
                        </Typography>
                        <Typography>
                            <FormattedMessage id="case" />-ID : {caseIdShort || <FormattedMessage id="unknown" />}
                        </Typography>
                    </Box>}
                    {debi.selected &&
                        <Box>
                            <Typography variant="h6">
                                Name:  {debi.company_name || <FormattedMessage id="unknown" />}
                            </Typography>
                            <Typography>
                                <FormattedMessage id="address" />:  {((debi.company_address_street === null ? '' : debi.company_address_street + ", ") +
                                    (debi.company_address_city === null ? '' : debi.company_address_city + ", ") +
                                    (debi.company_address_country === null ? '' : debi.company_address_country)) || <FormattedMessage id="unknown" />}
                            </Typography>
                            <br />
                            <Typography>
                                <FormattedMessage id="companies" />-ID: {debi.sap_debi_id || <FormattedMessage id="unknown" />}
                            </Typography>
                        </Box>}
                </CardContent>
                {!props.viewOnly && <CardActions>
                    {/* <Button
                        sx={styles.formControl}
                        color="primary"
                        variant="contained"
                        disabled={enableButton()}
                        onClick={handleOverview}>
                        <FormattedMessage id="overview" />
                    </Button> */}
                    <FormControl variant="outlined">
                        <InputLabel><FormattedMessage id="groups" /> (Optional)</InputLabel>
                        <Select
                            disabled={enableGroup}
                            value={groups || ''}
                            onChange={handleGroups}
                            label={<><FormattedMessage id="groups" /> (Optional)</>}
                            variant="outlined"
                            sx={{
                                minWidth: 290,
                                minHeight: 55,
                                // color: 'white',
                                // fontWeight: 'bold'
                            }}
                        >
                            <MenuItem value={''}>Keine Auswahl</MenuItem>
                            {groupList.map((name, index) =>
                                <MenuItem value={name} key={index.toString()}>{name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    {client.selected !== true && <Tooltip title={<FormattedMessage id="messageHardEscalation" />}>
                        <Button disabled={client.selected === true} color={debi.hard_escalation ? 'success' : 'error'} onClick={handleHardEscalationButton} sx={styles.formControl} variant="contained">
                            {debi.hard_escalation ? <FormattedMessage id="hardEscalationDeactivated" /> : <FormattedMessage id="hardEscalationActivated" />}
                        </Button>
                    </Tooltip>}
                </CardActions>}
            </Card>
            {/* <Accordion sx={{ marginTop: "0px !important" }}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography variant="h6"><FormattedMessage id="overview" /></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Divider />
                    {selectedClient.updates !== undefined && <Alert sx={{ marginTop: 1 }} severity="warning">
                        <FormattedMessage id="saveChangesOverviewDialog" />
                    </Alert>}
                    <OverviewDialog contactId={props.contactId} accountId={props.contactId} />
                </AccordionDetails>
            </Accordion> */}
            {!props.viewOnly && <>
                <HardEscalationDialog
                    open={open}
                    debi={debi}
                    closeHardEscalationDialog={closeHardEscalationDialog}
                    handleAcceptHardEscalation={handleAcceptHardEscalation}
                    handleRemoveHardEscalation={handleRemoveHardEscalation} />
                <SaveChangesOverviewDialog
                    openSaveChanges={openSaveChanges}
                    closeSaveChangesDialog={closeSaveChangesDialog}
                    acceptSaveChangesDialog={acceptSaveChangesDialog}
                />
                <SwitchUserDialog
                    dialogSwitchUser={dialogSwitchUser}
                    closeDialogSwitchUser={closeDialogSwitchUser}
                    switchUser={switchUser}
                />
            </>}
        </Box >

    )
}

export default ClientInfo