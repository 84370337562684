import React, { useState, useEffect, useMemo } from "react";
import { Grid, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Box, Stack, Alert, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, CardContent, Card, CircularProgress, Skeleton } from '@mui/material';
import isEmptyObj from '../helper/isEmptyObj'
import { ClientsService } from '../services/Clients'
import { FormattedMessage } from "react-intl";
import { locale_de } from "../translations/de";
import { useLocation, useNavigate } from "react-router-dom";


const classes = {
    paper: {
        padding: 1.5,
        backgroundColor: '#E0E0E0',
        margin: 1.5
    },
    paperNoWSP: {
        padding: 1.5,
        backgroundColor: '#f2f2f2',
        margin: 1.5
    },
    typography: {
        fontWeight: 'bold'
    },
    typographyNoWSP: {
        fontWeight: 'bold',
        color: '#cccccc'
    }
};

const OverviewDialog = (props: { accountId: string, contactId: string, selected: "ASP" | "DEBI" }) => {
    const location = useLocation()
    const { accountId, contactId } = props
    const [selectedClient, setSelectedClient] = useState<Record<string, any>>({})
    const [loading, setLoading] = useState(true)
    const translateBrands = (params: any) => {
        switch (params) {
            case 'all_brands':
                return locale_de.brandOptions.option1
            case 'haufe_brand':
                return locale_de.brandOptions.option2
            case 'lexware_brand':
                return locale_de.brandOptions.option3
            case 'akademie_brand':
                return locale_de.brandOptions.option4
            case 'sp_brand':
                return locale_de.brandOptions.option5
            default: return null
        }
    }

    const translateBrandsToImage = (params: any) => {
        switch (params) {
            case 'haufe_brand':
                return { logo: "/logos/Haufe_Logo_RGB.png", height: 50 }
            case 'lexware_brand':
                return { logo: "/logos/Lexware_LOGO.png", height: 20 }
            case 'akademie_brand':
                return { logo: "/logos/haufe-akademie-rgb-logo-main.png", height: 60 }
            case 'sp_brand':
                return { logo: "/logos/2560px-Schaeffer-poeschel.svg.png", height: 50 }
            default: return null
        }
    }

    const translateChannels = (params: any) => {
        switch (params) {
            case 'email':
                return locale_de.email
            case 'telephone':
                return locale_de.telephone
            case 'allPrint':
                return locale_de.allPrint
            case 'bigCatalog':
                return locale_de.bigCatalog
            case 'print<50pages':
                return locale_de['print<50pages']
            case 'marketResearch':
                return locale_de.marketResearch
            default: return null
        }
    }

    useEffect(() => {
        setLoading(true)
        setSelectedClient({})
        const fetchClient = async () => {
            if (props.selected === "ASP") {
                if (!accountId) {
                    console.log('No accountId provided')
                }
                try {
                    console.log('Requesting AnspBySF')
                    const result = await ClientsService.getAnspBySFId(contactId)
                    setSelectedClient(result)
                } catch (error) {
                    console.log('Error requesting AnspBySF', error)
                }
            }
            if (props.selected === "DEBI") {
                if (!accountId) {
                    console.log('No accountId provided')
                }
                try {
                    console.log('Requesting DebiBySF')
                    const result = await ClientsService.getDebiBySFId(accountId)
                    setSelectedClient(result)
                } catch (error) {
                    console.log('Error requesting DebiBySF', error)
                }
            }
            setLoading(false)
        }
        fetchClient()
    }, [props.selected])

    const brands = useMemo(() => {
        if (isEmptyObj(selectedClient)) return [[]]
        return Object.entries(selectedClient)
            .map(([key, value]) => [key, value])
            .filter((item: any) => item[0].includes('brand') && !item[0].includes('last_update_all_brands'))
    }, [selectedClient])

    const channels = (items: any) => {
        return Object.entries(items)
            .map(([key, value]) => [key, value])
            .filter((key) => key[0] !== locale_de.duration && key[1] === true)
            .map((item) => translateChannels(item[0]))
    }


    return (
        <Card sx={{ marginBottom: 5, marginTop: 2 }}>
            <CardContent>
                <Typography variant="h6" >
                    <FormattedMessage id="overview" />
                </Typography>
                {loading && <Stack spacing={1} sx={{ marginTop: 1 }}>
                    <Skeleton variant="text" height={70} />
                    <Skeleton variant="text" height={70} />
                    <Skeleton variant="text" height={70} />
                    <Skeleton variant="text" height={70} />
                    <Skeleton variant="text" height={70} />
                </Stack>}
                {!loading && Object.keys(selectedClient).length === 0 && <Box sx={{ marginTop: 1 }}><Alert severity="warning"><FormattedMessage id="noData" /></Alert></Box>}
                {!isEmptyObj(selectedClient) && <Stack>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                {brands.map((item: any, index) =>
                                    <>
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" sx={{ maxWidth: 120 }}>
                                                <Typography variant="h6">
                                                    {translateBrandsToImage(item[0]) ? <img style={{ maxHeight: translateBrandsToImage(item[0])?.height }} src={translateBrandsToImage(item[0])?.logo ?? undefined} /> : translateBrands(item[0])}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                {channels(item[1]).length !== 0 ?
                                                    <Stack direction={"row"}>
                                                        <Grid container direction='column' xs={2} >
                                                            <Grid item>
                                                                <Typography variant="overline" >
                                                                    <FormattedMessage id="level" />
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant='body2' sx={classes.typography}>
                                                                    {selectedClient[item[0]].level || <FormattedMessage id="unknown" />}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container direction='column' xs={3} >
                                                            <Grid item >
                                                                <Typography variant="overline" >
                                                                    <FormattedMessage id="department" />
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item >
                                                                <Typography variant='body2' sx={classes.typography}>
                                                                    {(selectedClient[item[0]].department && selectedClient[item[0]].department.join(', ')) || <FormattedMessage id="unknown" />}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container direction='column' xs={2}  >
                                                            <Grid item>
                                                                <Typography variant="overline" >
                                                                    <FormattedMessage id="groups" />
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant='body2' sx={classes.typography}>
                                                                    {selectedClient.groups || <FormattedMessage id="unknown" />}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container direction='column' xs={2} >
                                                            <Grid item>
                                                                <Typography variant="overline" >
                                                                    <FormattedMessage id="channels" />
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant='body2' sx={classes.typography}>
                                                                    {channels(item[1]).join(', ')}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container direction='column' xs={1} >
                                                            <Grid item>
                                                                <Typography variant="overline" >
                                                                    <FormattedMessage id="duration" />
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant='body2' sx={classes.typography}>
                                                                    {item[1].duration || <FormattedMessage id="unknown" />}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Stack> :
                                                    <>
                                                        <Alert severity="success" sx={{
                                                            backgroundColor: "#D2FF5A",
                                                            "& .MuiAlert-icon": {
                                                                color: "#4CAF50"
                                                            }
                                                        }}>
                                                            <FormattedMessage id="noWSP" />
                                                        </Alert>
                                                    </>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    </>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Stack>}

            </CardContent>
        </Card>
    )
}

export default OverviewDialog


