import { locale_de } from "../translations/de"
import isEmptyObj from './isEmptyObj'

export default function translateInitialClient(debi: any) {

    return {
        ...(!isEmptyObj(debi.all_brands) && {
            [locale_de.brandOptions.option1]: {
                [locale_de.duration]: debi.all_brands.duration,
                ...(debi.all_brands.email === true) && { [locale_de.email]: locale_de.true },
                ...(debi.all_brands.allPrint === true) && { [locale_de.allPrint]: locale_de.true },
                ...(debi.all_brands.bigCatalog === true) && { [locale_de.bigCatalog]: locale_de.true },
                ...(debi.all_brands.telephone === true) && { [locale_de.telephone]: locale_de.true },
                ...(debi.all_brands['print<50pages'] === true) && { [locale_de['print<50pages']]: locale_de.true },
                ...(debi.all_brands.marketResearch === true) && { [locale_de.marketResearch]: locale_de.true },
                [locale_de.level]: debi.all_brands.level,
                [locale_de.department]: debi.all_brands.department
            }
        }),
        ...(!isEmptyObj(debi.haufe_brand) && {
            [locale_de.brandOptions.option2]: {
                [locale_de.duration]: debi.haufe_brand.duration,
                ...(debi.haufe_brand.email === true) && { [locale_de.email]: locale_de.true },
                ...(debi.haufe_brand.allPrint === true) && { [locale_de.allPrint]: locale_de.true },
                ...(debi.haufe_brand.bigCatalog === true) && { [locale_de.bigCatalog]: locale_de.true },
                ...(debi.haufe_brand.telephone === true) && { [locale_de.telephone]: locale_de.true },
                ...(debi.haufe_brand['print<50pages'] === true) && { [locale_de['print<50pages']]: locale_de.true },
                ...(debi.haufe_brand.marketResearch === true) && { [locale_de.marketResearch]: locale_de.true },
                [locale_de.level]: debi.haufe_brand.level,
                [locale_de.department]: debi.haufe_brand.department
            }
        }),
        ...(!isEmptyObj(debi.lexware_brand) && {
            [locale_de.brandOptions.option3]: {
                [locale_de.duration]: debi.lexware_brand.duration,
                ...(debi.lexware_brand.email === true) && { [locale_de.email]: locale_de.true },
                ...(debi.lexware_brand.allPrint === true) && { [locale_de.allPrint]: locale_de.true },
                ...(debi.lexware_brand.bigCatalog === true) && { [locale_de.bigCatalog]: locale_de.true },
                ...(debi.lexware_brand.telephone === true) && { [locale_de.telephone]: locale_de.true },
                ...(debi.lexware_brand['print<50pages'] === true) && { [locale_de['print<50pages']]: locale_de.true },
                ...(debi.lexware_brand.marketResearch === true) && { [locale_de.marketResearch]: locale_de.true },
                [locale_de.level]: debi.lexware_brand.level,
                [locale_de.department]: debi.lexware_brand.department
            }
        }),
        ...(!isEmptyObj(debi.akademie_brand) && {
            [locale_de.brandOptions.option4]: {
                [locale_de.duration]: debi.akademie_brand.duration,
                ...(debi.akademie_brand.email === true) && { [locale_de.email]: locale_de.true },
                ...(debi.akademie_brand.allPrint === true) && { [locale_de.allPrint]: locale_de.true },
                ...(debi.akademie_brand.bigCatalog === true) && { [locale_de.bigCatalog]: locale_de.true },
                ...(debi.akademie_brand.telephone === true) && { [locale_de.telephone]: locale_de.true },
                ...(debi.akademie_brand['print<50pages'] === true) && { [locale_de['print<50pages']]: locale_de.true },
                ...(debi.akademie_brand.marketResearch === true) && { [locale_de.marketResearch]: locale_de.true },
                [locale_de.level]: debi.akademie_brand.level,
                [locale_de.department]: debi.akademie_brand.department
            }
        }),
        ...(!isEmptyObj(debi.sp_brand) && {
            [locale_de.brandOptions.option5]: {
                [locale_de.duration]: debi.sp_brand.duration,
                ...(debi.sp_brand.email === true) && { [locale_de.email]: locale_de.true },
                ...(debi.sp_brand.allPrint === true) && { [locale_de.allPrint]: locale_de.true },
                ...(debi.sp_brand.bigCatalog === true) && { [locale_de.bigCatalog]: locale_de.true },
                ...(debi.sp_brand.telephone === true) && { [locale_de.telephone]: locale_de.true },
                ...(debi.sp_brand['print<50pages'] === true) && { [locale_de['print<50pages']]: locale_de.true },
                ...(debi.sp_brand.marketResearch === true) && { [locale_de.marketResearch]: locale_de.true },
                [locale_de.level]: debi.sp_brand.level,
                [locale_de.department]: debi.sp_brand.department
            }
        }),
        ...(!isEmptyObj(debi.all_brands) && !isEmptyObj(debi.haufe_brand) && !isEmptyObj(debi.lexware_brand) && !isEmptyObj(debi.akademie_brand) && !isEmptyObj(debi.sp_brand) && { [locale_de.groups]: debi.groups })
    }
}