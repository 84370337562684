import React from "react";
import { Paper, Box } from '@mui/material';
import Header from './Header'
import ClientInfo from './ClientInfo'
import ContactPerson from './ContactPerson'
import Channels from './Channels'
import ConfirmationDialog from './ConfirmationDialog'
import Progress from './Progress'
import isEmptyObj from '../helper/isEmptyObj'
import Error from './Error'
import { ClientsService } from '../services/Clients'
import { FormattedMessage } from "react-intl";

import translateInitialClient from '../helper/translateInitialClient'
import { locale_de } from "../translations/de";
import { Footer } from "./Footer";
import OverviewDialog from "./OverviewDialog";
import { getSession } from "../auth/CognitoAuth";

class Client extends React.Component {
  constructor(props) {
    super(props)
    const searchParams = new URLSearchParams(window.location.search)

    this.state = {
      caseId: searchParams.get('caseId'),
      accountId: searchParams.get('accountId') || null,
      contactId: searchParams.get('contactId') || null,
      client: {},
      debi: {},
      selectedBrand: '',
      open: false,
      alertSaveClient: false,
      loading: false,
      overview: false,
      loggedInUser: props.user,
      caseIdShort: null,
      viewOnly: true,
    }

  }

  componentDidMount = async () => {
    console.log('Clients component mounted')
    const session = await getSession();
    if (!session) {
      window.location.href = '/'
    }
    const urlParams = new URLSearchParams(window.location.search)
    const requestViewOnlyByCaseLogic = urlParams.get('caseId') === null;
    const requestViewOnlyByParam = urlParams.get('viewOnly') === 'true' ? true : false

    const hasParamsOnlyContactId = urlParams.get('contactId') !== null && urlParams.get('accountId') === null && urlParams.get('caseId') === null

    const requestViewOnly = requestViewOnlyByCaseLogic || requestViewOnlyByParam || hasParamsOnlyContactId
    const roles = session.idToken.payload["custom:groups"] ?? []
    if (roles.includes("Editor")) {
      this.setState({ viewOnly: requestViewOnly })
      console.log("User is Editor and can edit", requestViewOnlyByCaseLogic, requestViewOnlyByParam)
    } else {
      this.setState({ viewOnly: true })
      console.log("User is not Editor and can only view", roles)
    }
    await this.getClients()
  }

  getClients = async () => {
    const { caseId, contactId, accountId,viewOnly } = this.state

    try {
      let client = contactId !== null ? await ClientsService.getAnspBySFId(contactId) : {}
      let debi = await ClientsService.getDebiBySFId(accountId)
      let caseIdShort = await ClientsService.getShortCaseId(caseId) || null

      if (debi.sf_debi_id !== null) {
        debi = {
          ...debi,
          case_id: caseId,
          selected: debi.hard_escalation === true || isEmptyObj(client) ? true : false
        }
      }
      if (!isEmptyObj(client)) {
        client = {
          ...client,
          case_id: caseId,
          selected: debi.hard_escalation === true ? false : true
        }
      }

      this.setState({
        debi: {
          ...debi,
          initialBans: translateInitialClient(debi)
        },
        client, caseIdShort
      })

      //Needed for 'reversing'  hard escalation clear debi updates
      localStorage.setItem('storedCompany', JSON.stringify(this.state.debi))

      //Needed for clear client updates
      localStorage.setItem('storedClient', JSON.stringify(this.state.client))

    } catch (err) {
      console.error(err)
    }
  }

  getSelectedClient = () => this.state.client && this.state.client.selected === true ? this.state.client : this.state.debi

  hideLoader = () => {
    this.setState({ loading: false });
  }

  showLoader = () => {
    this.setState({ loading: true });
  }

  goToOverviewClient = () => {
    const { client, debi } = this.state
    window.location.href = `/overview?${client.selected === true ? 'contactId=' + client.sf_ansp_id : 'accountId=' + debi.sf_debi_id}`
  }

  enableSave = () => {
    const selectedClient = this.getSelectedClient()
    const validateLevelIsSetForEveryBrand = () => {
      let updatedBrands = []
      let hasLevelProp = 0
      for (const props in selectedClient)
        if (typeof selectedClient[props] === 'object' && (selectedClient[props] && Object.entries(selectedClient[props]).length !== 0) && props !== 'updates' && props !== 'initialBans')
          updatedBrands.push(selectedClient[props])

      updatedBrands.forEach(item => {
        if (item.hasOwnProperty('level'))
          if ((item.level === locale_de.levelOptions.option1 && item.department && item.department.length !== 0) || (item.level !== locale_de.levelOptions.option1 && item.level !== undefined))
            hasLevelProp++
      })
      if (updatedBrands.length === hasLevelProp)
        return false
      else return true
    }

    if (selectedClient.updates !== undefined)
      if (selectedClient.company_wsp === true) {
        if (selectedClient.hard_escalation && selectedClient.hard_escalation === true)
          return false
        else if (selectedClient.updates.Alle && Object.values(selectedClient.updates.Alle).length === 0 && selectedClient.updates.Besonderheiten === null)
          return false
        else return validateLevelIsSetForEveryBrand()
      }
      else if (selectedClient.company_wsp !== true)
        return false
      else return true
    else return true
  }


  handleConfirm = async () => {
    const { overview } = this.state
    let response = null

    this.setState({ open: false })
    this.showLoader()

    try {
      response = this.getSelectedClient().id ? await ClientsService.updateClient(this.getSelectedClient()) :
        await ClientsService.createClient(this.getSelectedClient())
      if (response && response.status < 400) {
        overview === true ? this.goToOverviewClient() : this.getClients()
          .then(() => this.hideLoader())
          .then(() => this.closeAlertSaveClient())
      }
      else {
        this.hideLoader()
        this.setState({ alertSaveClient: true })
      }
    } catch (err) {
      console.error(err)
    }
  }

  submit = () => {
    this.setState({ open: true })
  }

  cancel = () => {
    this.goHome()
  }

  setClient = (client) => {
    this.setState({ client })
  }

  setBrand = (selectedBrand) => {
    this.setState({ selectedBrand })
  }

  setDebi = (debi) => {
    this.setState({ debi })
  }

  setClientSelection = (company_wsp) => {
    this.setState({
      debi: {
        ...this.state.debi,
        selected: company_wsp
      },
      client: {
        ...this.state.client,
        selected: !company_wsp
      }
    })
  }

  closeAlertSaveClient = () => {
    this.setState({ alertSaveClient: false })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  goHome = () => {
    window.location.href = '/'
  }

  enableOverviewClient = () => {
    this.setState({ overview: true })
  }

  render() {
    const { open, client, selectedBrand, caseIdShort, alertSaveClient, debi, loading, loggedInUser } = this.state

    if (!isEmptyObj(debi) && loading === false) {
      return (
        <Paper sx={{ borderRadius: 0, boxShadow: "none" }}>
          <Error
            alert={alertSaveClient}
            closeAlert={this.closeAlertSaveClient}
          />
          <Header
            selectedClient={this.getSelectedClient()}
            submit={this.submit}
            cancel={this.cancel}
            brand={selectedBrand}
            // loggedInUser={loggedInUser}
            enableSave={this.enableSave}
          />
          <Box sx={{ marginLeft: 2, paddingRight: 2, marginTop: 4 }}>
            <ClientInfo
              accountId={this.state.accountId}
              contactId={this.state.contactId}
              client={client}
              debi={debi}
              caseIdShort={caseIdShort}
              enableOverviewClient={this.enableOverviewClient}
              enableSave={this.enableSave}
              goToOverviewClient={this.goToOverviewClient}
              submit={this.submit}
              updateClient={this.setClient}
              updateDebi={this.setDebi}
              setClientSelection={this.setClientSelection}
              viewOnly={this.state.viewOnly}
            />
            {!this.state.viewOnly && <>
              <Channels
                updateClient={this.setClient}
                updateDebi={this.setDebi}
                client={client}
                debi={debi}
                updateBrand={this.setBrand}
                enableSave={this.enableSave}
                submit={this.submit}
              />
              <ContactPerson
                client={client}
                debi={debi}
                brand={selectedBrand}
                updateClient={this.setClient}
                updateDebi={this.setDebi}
              />
            </>
            }
            <OverviewDialog selected={client.selected ? "ASP" : "DEBI"} contactId={this.state.contactId} accountId={this.state.accountId} />
            <ConfirmationDialog
              open={open}
              handleConfirm={this.handleConfirm}
              handleClose={this.handleClose}
              client={client}
              debi={debi}
              brand={selectedBrand}
              loggedInUser={loggedInUser}
            />
          </Box>
          <Footer />
        </Paper>
      )
    } else {
      return <Progress
        message={<FormattedMessage id="noWSPDisplay" />} />
    }
  }
}

export default Client;
