import axios, { Axios } from "axios";
import { BackendConfigJson, getBackendConfig } from "../helper/BackendInfrastructure";
import { getSession } from "../auth/CognitoAuth";

class Clients {
    private backendConfig: BackendConfigJson | undefined;
    private axiosInstance: Axios | undefined;
    constructor() {
        getBackendConfig().then((config) => {
            if (!config.success) {
                console.error('Failed to get backend config')
                throw new Error('Failed to get backend config')
            }
            this.backendConfig = config.config;
        }).catch(err => console.error(err));
    }

    private async getAxiosInstance() {
        if (!this.axiosInstance) {
            const session = await getSession();
            if (session) {
                this.axiosInstance = axios.create({
                    headers: {
                        Authorization: `Bearer ${session.idToken.toString()}`
                    }
                })
            }
        }
        if (!this.axiosInstance) throw new Error('Failed to get axios instance')
        return this.axiosInstance;
    }

    updateClient = async (data: any) => {
        return (await this.getAxiosInstance()).put(this.backendConfig?.AppBackendUrl + `/clients/${data.id}`, data)
            .then(response => response)
            .catch(err => console.error(err))
    }

    createClient = async (data: unknown) => {
        return (await this.getAxiosInstance()).post(this.backendConfig?.AppBackendUrl + "/clients", data)
            .then(response => response)
            .catch(err => console.error(err))
    };

    getAnspBySFId = async (id: string) => {
        return (await this.getAxiosInstance()).get(this.backendConfig?.AppBackendUrl + `/clients/contacts/SFId=${id}`)
            .then(res => res.data)
            .catch(err => console.error(err))
    }

    getDebiBySFId = async (id: string) => {
        try {
            const request = await (await this.getAxiosInstance()).get(this.backendConfig?.AppBackendUrl + `/clients/accounts/SFId=${id}`)
            return request.data
        } catch (error) {
            return {}
        }
    }

    getShortCaseId = async (id: string) => {
        return (await this.getAxiosInstance()).get(this.backendConfig?.AppBackendUrl + `/clients/caseId=${id}`)
            .then(res => res.data)
            .catch(err => console.error(err))
    }

}

export const ClientsService = new Clients()