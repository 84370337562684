export const locale_en = {
    "all": "All",
    "true": "true",
    "false": "false",
    "email": "E-Mail",
    "personalWSPTitle": "Personal",
    "companyWSPTitle": "Company",
    "buttonCancelText": "Cancel",
    "buttonSaveText": "Save",
    "buttonCreateNewClientText": "Create new client",
    "errorWarningOnSave": "An error occurred while saving! Please try again. If the problem persists, contact support.",
    "warningHardEscalationOn": "Hard escalation is activated. You cannot change to personal wsp.",
    "warningNoContactId": "There is no contact ID for this case. A personal ban cannot currently be saved!",
    "case": "Case",
    "unknown": "unknown",
    "contactPerson": "Contact-person",
    "companies": "Companies",
    "address": "Address",
    "overview": "Overview",
    "hardEscalationDeactivated": "Deactivate Hard escalation",
    "hardEscalationActivated": "Activate Hard escalation",
    "messageHardEscalation": "In the event of a \"Hard Escalation\", an advertising block is automatically issued for all channels, brands and contact persons, with no time limit (forever).",
    "hardEscalationDialogActivate": "Are you sure you want to activate the \"Hard Escalation\"?",
    "hardEscalationDialogDeactivate": "Are you sure you want to deactivate the \"Hard Escalation\"?",
    "confirm": "Yes",
    "deny": "No",
    "saveChangesOverviewDialog": "Please save your changes. The overview can only be refreshed after saving.",
    "switchUserDialog": "If you switch between contact person and company and do not save beforehand, your previous settings will be lost.You can issue an advertising block either only for the contact person or for the company. Not for both in one step.Example: You first have to save and confirm your changes to the contact person. And then restart the advertising blocking tool to adapt the advertising blocking to the company.",
    "switch": "Switch",
    "warning": "Warning!",
    "appliesTo": "Applies to",
    "level": "Level",
    "groups": "Groups",
    "department": "Department",
    "channels": "Channels",
    "brand": "Brand",
    "duration": "Duration",
    "noEmailWarning": "There is no e-mail for this client, so the e-mail ban cannot be set!",
    "noPhoneWarning": "There is no telephone for this customer, so the telephone ban cannot be set!",
    "allPrint": "Big Print",
    "bigCatalog": "Big Catalog",
    "telephone": "Telephone",
    "print<50pages": "Print <50 pages",
    "marketResearch": "Market Reasearch",
    "allChannels": "All Channels",
    "noWSPDisplay": "Something went wrong! WSP cannot be displayed.",
    "dialogConfirmationTitle": "Confirmation overview",
    "temper": "Temper",
    "noContactChanges": "There are no contact changes!",
    "validFrom": "Valid From",
    "noChannelsChanges": "There are no channel changes!",
    "confirmDialogOverview": "Confirm Selection",
    "loadingMsg": "Loading, please wait!",
    "noWSP": "NO WSP",
    "goBack": "Go Back",
    "noChangedData": "There are no changes",
    "noData": "No data found",
    "levelDepartmentDisabled": "No restriction possible for this brand",
    "levelDepartmentDisabledAsp": "No restrictions possible for this contact person",
}