import React, { useState, useEffect } from 'react';
import { CircularProgress, Typography, Grid, Button, Stack, Paper, Box, Alert } from '@mui/material';
import history from './../helper/history'
import { FormattedMessage } from "react-intl";


const CircularIndeterminate = (props) => {
    // const classes = useStyles();
    const [timer, setTimer] = useState(false)
    const time = 25000

    useEffect(() => {
        window.scrollTo(0, 0);
        const timer = setTimeout(() => {
            setTimer(true)
        }, time);
        return () => clearTimeout(timer);
    }, []);


    return (
        <Box>
            <Stack sx={{ marginTop: 10 }} alignItems={"center"}>
                <img style={{ maxHeight: 100 }} src="/HG_Logo_Standard_quer_einzeilig_sv_RGB.png" />
                <Typography variant="h3" sx={{ marginTop: 4 }} color="primary">Werbesperre Service</Typography>
                {!timer ? <CircularProgress size={100} sx={{ marginTop: 4 }} /> : <Alert sx={{ marginTop: 2 }} severity="error">{props.message}</Alert>}
            </Stack>
        </Box>
    );
}

export default CircularIndeterminate