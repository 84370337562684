import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { FormattedMessage } from "react-intl";

const SaveChangesOverviewDialog = (props) => {

    return (
        <Dialog open={props.openSaveChanges}>
            <DialogContent >
                <DialogContentText >
                    <FormattedMessage id="saveChangesOverviewDialog" />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.closeSaveChangesDialog} >
                    <FormattedMessage id="deny" />
                </Button>
                <Button variant="contained" color="primary" onClick={props.acceptSaveChangesDialog} autoFocus>
                    <FormattedMessage id="confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SaveChangesOverviewDialog