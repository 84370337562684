export const locale_de = {
    "all": "Alle",
    "true": "gesperrt",
    "false": "frei",
    "email": "E-Mail",
    "personalWSPTitle": "Ansprechpartner",
    "companyWSPTitle": "Firma",
    "buttonCancelText": "Abbruch",
    "buttonSaveText": "Speichern",
    "buttonCreateNewClientText": "Neuen Client erstellen",
    "errorWarningOnSave": "Beim Speichern ist ein Fehler aufgetreten! Bitte versuche es erneut. Wenn das Problem weiterhin besteht, wenden Sie sich an den Support.",
    "warningHardEscalationOn": "Die „Harte Eskalation“ ist aktiviert. Diese gilt automatisch auch für den Ansprechpartner, welcher dann nicht verändert werden kann.",
    "warningNoContactId": "Für diesen Fall gibt es keine Kontakt-ID. Ein persönliches Verbot kann derzeit nicht gespeichert werden!",
    "case": "Kundenvorgangs",
    "unknown": "unbekannt",
    "companies": "Firmen",
    "address": "Adresse",
    "overview": "Übersicht",
    "hardEscalationDeactivated": "Harte Eskalation deaktivieren",
    "hardEscalationActivated": "Harte Eskalation aktivieren",
    "messageHardEscalation": "\"Im Fall einer harten Eskalation\" wird automatisch ein Werbesperre für  alle Kanäle, Marken und Ansprechpartner vergeben,  ohne zeitliche Begrenzung (für immer).",
    "hardEscalationDialogActivate": " Sind Sie sicher, dass Sie die \"harte Eskalation\" aktivieren möchten?",
    "hardEscalationDialogDeactivate": " Sind Sie sicher, dass Sie die \"harte Eskalation\" deaktivieren möchten?",
    "confirm": "Ja",
    "deny": "Nein",
    "saveChangesOverviewDialog": "Bitte speichern Sie Ihre Änderungen. Die Übersicht kann erst nach erfolgtem Speichern aktualisiert werden.",
    "switchUserDialog": "Wenn Sie zwischen Ansprechpartner und Firma wechseln und vorher nicht speichern, gehen Ihre bisher getätigten Einstellungen verloren.Sie können eine Werbesperre entweder nur für den Ansprechpartner oder für die Firma vergeben. Nicht für beides in einem Schritt.Beispiel: Sie müssen zunächst Ihre Änderungen am Ansprechpartner speichern und bestätigen. Und dann wieder das Werbesperr-Tool neu starten um die Werbesperre an die Firma anzupassen.",
    "switch": "Wechseln",
    "warning": "Warnung!",
    "appliesTo": "Gilt für",
    "level": "Ebene",
    "groups": "Besonderheiten",
    "department": "Themenbereich",
    "channels": "Kanäle",
    "brand": "Marke",
    "duration": "Dauer",
    "noEmailWarning": "Es gibt keine E-Mail für diesen Client, daher kann das E-Mail-Verbot nicht festgelegt werden!",
    "noPhoneWarning": "Für diesen Kunden gibt es kein Telefon, daher kann das Telefonverbot nicht festgelegt werden!",
    "allPrint": "Briefe und Kataloge",
    "bigCatalog": "große Kataloge",
    "telephone": "Telefonwerbung",
    "print<50pages": "Kleine Kataloge",
    "marketResearch": "Marktforschung",
    "allChannels": "Alle Kanäle",
    "noWSPDisplay": "Etwas ist schief gelaufen! WSP kann nicht angezeigt werden.",
    "dialogConfirmationTitle": "Übersicht zur Bestätigung",
    "temper": "Härtegrad",
    "noContactChanges": "Es gibt keine Änderungen!",
    "validFrom": "Gültig ab",
    "noChannelsChanges": "Es gibt keine Kanalwechsel!",
    "confirmDialogOverview": "Auswahl bestätigen",
    "loadingMsg": "Laden, bitte warten!",
    "noWSP": "Keine Werbesperre",
    "goBack": "Schließen",
    "levelOptions": {
        "option1": "Keine Werbung für Themenbereich",
        "option2": "Alle Kontakte gesperrt (Account / Pseudopersonalisierung frei)",
        "option3": "Account / Pseudopersonalisierungen gesperrt (Alle Kontakte frei)",
        "option4": "Account und alle Kontakte gesperrt"
    },
    "groupOptions": {
        "option1": "kritische Zielgruppe (zB. Anwälte)",
        "option2": "C-Level / Geschäftsführung",
        "option3": "Universitäten / Hochschulen"
    },
    "departmentOptions": {
        "option1": "Personal",
        "option2": "Controlling",
        "option3": "Arbeitssicherheit / Datenschutz",
        "option4": "Steuerberatung",
        "option5": "Rechtsprechung",
        "option6": "Marketing / Vertrieb",
        "option7": "Einkauf / Logistik",
        "option8": "Projekt-Management",
        "option9": "Immobilien",
        "option10": "Finanzen / Buchhaltung",
        "option11": "Führung / allg. Management"
    },
    "brandOptions": {
        "option1": "Alle",
        "option2": "Haufe",
        "option3": "Lexware",
        "option4": "Akademie",
        "option5": "Schäffer-Poeschel"
    },
    "durationOptions": {
        "option1": "6 Monate",
        "option2": "12 Monate",
        "option3": "2 Jahre",
        "option4": "5 Jahre",
        "option5": "Für immer"
    },
    "noChangedData": "Keine Änderungen",
    "noData": "Keine Werbesperre gefunden!",
    "levelDepartmentDisabled": "Keine Einschränkung für diese Marke möglich",
    "levelDepartmentDisabledAsp": "Keine Einschränkungen für Ansprechpartner möglich"
}